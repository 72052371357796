import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/NavBar';
import Calendar from './Components/Calendar';
import SearchBar from './Components/SearchBar';
import 'bootstrap/dist/css/bootstrap.css';
import styled from 'styled-components';
import gameCornerLogo from './Assets/logos/gamecorner-logo.png';
import cardReviveLogo from './Assets/logos/cardrevive-logo.png';
import appLogo from './Assets/logos/tcg-calendar-logo.png';
import NextEvent from './Components/NextEvent';

const AppContainer = styled.div`
  max-width: 1200px; // Adjust this value as needed
  margin: 0 auto; // This will center the container
  padding: 20px; // This adds some space around the content
`;

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <Router>
      <Navbar
        logo={appLogo}
        links={[
          { src: cardReviveLogo, alt: 'cardrevive', url: 'https://cardrevive.com.au' },
          { src: gameCornerLogo, alt: 'gamecorner', url: 'https://linktr.ee/gamecornerpb' },
        ]}
      />
      <AppContainer>
        <SearchBar onSearch={handleSearch} />
        <Routes>
          <Route path="/" element={
            <>
              <Calendar searchTerm={searchTerm} endpoint="/api/events" />
              <NextEvent />
            </>
          } />
          <Route path="/pokemon-competitions-nsw" element={
            <Calendar searchTerm={searchTerm} endpoint="/api/competitions" />
          } />
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
