// SearchBar.tsx

import React, { useState } from "react";
// import './SearchBar.css';

interface Props {
  onSearch: (term: string) => void;
}

const SearchBar: React.FC<Props> = ({ onSearch }) => {
  const [term, setTerm] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowerCaseTerm = e.target.value.toLowerCase();
    setTerm(lowerCaseTerm);
    onSearch(lowerCaseTerm);
  };

    return (
      <div style={SearchBarContainerStyle}>
        <div style={SearchBarStyle}>
          <input
          type="text"
          value={term}
          onChange={handleInputChange}
          className="form-control"
          placeholder="Search by title, tag, or location..."
        />
      </div>
      <a
        href="https://forms.gle/rH56xpcpHVaYymTP7"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-light"
        style={ButtonStyle}
      >
        Add Event
      </a>
    </div>
  );
};

export default SearchBar;

const SearchBarContainerStyle = {
  display: "flex", // Use flexbox layout
  justifyContent: "space-between", // Distribute items evenly in the container
  alignItems: "center", // Center items vertically
};

const SearchBarStyle = {
  padding: "20px",
  width: "70%", // Set the width of the search bar to 70% of the container width
};

const ButtonStyle = {
  width: "35%", // Set the width of the button to 20% of the container width
  border: "1px solid #000000",
  fontWeight: 'bold',
};