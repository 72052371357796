import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

export const NextEventContainer = styled.div`
  margin-top: 30px;
  padding: 10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  box-shadow: 10px 10px 1px rgba(0, 0, 0, 100);
  border: 3px solid #000000;
  text-align: center; /* Center all items */

  @media (max-width: 768px) {
    padding: 5px; /* Adjust padding for mobile screens */
  }
`;

export const EventContent = styled.div`
  display: flex;
  flex-direction: row; /* Change to row to place image on the left */
  align-items: center;
  justify-content: center; /* Center items horizontally */
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile screens */
  }
`;

export const EventImageContainer = styled.div`
  background-color: #3788d8; 
  border: 2px solid #000000; /* Black border */
  border-radius: 5px;
  padding: 10px; /* Add padding here */
  margin-right: 20px; /* Add margin to the right to space out the image and text */

  @media (max-width: 768px) {
    margin-right: 0; /* Remove margin for mobile screens */
    margin-bottom: 10px; /* Add margin to the bottom for spacing */
  }
`;

export const EventImage = styled.img`
  width: 30vh;

  @media (max-width: 768px) {
    width: 20vh; /* Adjust width for mobile screens */
  }
`;

export const EventDetails = styled.div`
  padding: 10px; /* Add padding here */

  @media (max-width: 768px) {
    padding: 5px; /* Adjust padding for mobile screens */
  }
`;

export const EventButton = styled.a`
  display: inline-block;
  padding: 10px 10px;
  background-color: #3788d8;
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  border: 2px solid #000000;
  cursor: pointer;
  &:hover {
    background-color: #2a6bbf;
  }

  @media (max-width: 768px) {
    width: 100%; /* Make button full width on mobile screens */
    margin-bottom: 10px; /* Add margin to the bottom for spacing */
  }
`;

export const AddToCalendarButton = styled.a`
  display: inline-block;
  padding: 10px 10px;
  margin-left: 10px;
  background-color: black;
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  border: 2px solid #000000;
  cursor: pointer;
  &:hover {
    background-color: #2a6bbf;
  }

  @media (max-width: 768px) {
    width: 100%; /* Make button full width on mobile screens */
    margin-left: 0; /* Remove left margin for mobile screens */
  }
`;

interface Event {
  id: string;
  title: string;
  description: string;
  start: string;
  end: string;
  url: string;
  image?: string;
  location?: string;
  descriptionUrl?: string;
  ticketCost?: string;
  tags?: string[];
}

const NextEvent: React.FC = () => {
  const [upcomingEvent, setUpcomingEvent] = useState<Event | null>(null);

  useEffect(() => {
    const getEvents = async () => {
      const response = await axios.get('/api/events');
      const events: Event[] = response.data;
      const futureEvents = events.filter(event => new Date(event.start) > new Date());
      const nextEvent = futureEvents.reduce((prev: Event, curr: Event) =>
        new Date(prev.start) < new Date(curr.start) ? prev : curr
      );
      setUpcomingEvent(nextEvent);
    };

    getEvents();
  }, []);

  if (!upcomingEvent) return <NextEventContainer>No upcoming events.</NextEventContainer>;

  return (
    <NextEventContainer>
      <h3>Upcoming Event</h3>
      <EventContent>
        {upcomingEvent.image && (
          <EventImageContainer>
            <EventImage src={upcomingEvent.image} alt={upcomingEvent.title} />
          </EventImageContainer>
        )}
        <EventDetails>
          <h3><strong>{upcomingEvent.title}</strong></h3>
          <p><strong>Event Start: {upcomingEvent.start}</strong></p>
          <p><strong>Price of Admission: {upcomingEvent.ticketCost}</strong></p>
          <EventButton href={upcomingEvent.descriptionUrl} target="_blank">Event Website</EventButton>
          <AddToCalendarButton href={upcomingEvent.url} target="_blank">Add to Calendar</AddToCalendarButton>
        </EventDetails>
      </EventContent>
    </NextEventContainer>
  );
};

export default NextEvent;
