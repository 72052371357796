import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const Banner = styled.div`
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Adjust font size for mobile screens */
    padding: 8px; /* Adjust padding for mobile screens */
  }
`;

const NavbarStyled = styled.nav`
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on mobile screens */
    padding: 10px; /* Adjust padding for mobile screens */
  }
`;

const NavbarBrand = styled(Link)`
  display: flex;
  align-items: center;

  img {
    max-width: 100%; // Set the max width of the logo
    height: 40px; // Default height for larger screens
    min-height: 10px;

    @media (max-width: 768px) { // Adjust for mobile screens
      height: auto; // Set height to auto for mobile screens
    }
      &:hover {
        filter: grayscale(40%);
        transform: scale(1.2);
        transition: transform 0.5s ease;
    }
  }
`;

const NavbarMenu = styled.div`
  display: flex;
  justify-content: flex-end; // Align items to the right side
  gap: 40px;

  @media (max-width: 768px) { // Adjust for mobile screens
    gap: 5px; // Set gap to smaller value for mobile screens
    justify-content: center; // Center items on mobile screens
    flex-wrap: wrap; // Allow items to wrap on mobile screens
  }
`;

const NavbarLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: black;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: #007bff;
    text-decoration: underline;
  }

  img {
    max-width: 100%; // Set the max width of the image
    min-height: 20px;
    height: 40px; // Default height for larger screens
    
    @media (max-width: 768px) { // Adjust for mobile screens
      height: 40px; // Set height to auto for mobile screens
    }
      &:hover {
        filter: grayscale(100%);
        transform: scale(1.2);
        transition: transform 0.5s ease;
    }
  }
`;

const DropdownStyled = styled(Dropdown)`
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    color: black;
    font-weight: bold;
    padding: 0;
    &:after {
      display: none;
    }
    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }

  .dropdown-menu {
    background-color: white;
    border: none;
    box-shadow: none;
  }

  .dropdown-item {
    color: black;
    &:hover {
      background-color: #f8f9fa;
      color: #007bff;
    }
  }
`;

const PoweredBy = styled.b`
  font-size: .75vw; // Use viewport width for font size

  @media (max-width: 768px) { // Adjust for mobile screens
    font-size: 2vw; // Increase font size for mobile screens
  }
`;

interface Props {
  logo: string;
  links: { src: string; alt: string; url: string }[];
}

const Navbar: React.FC<Props> = ({ logo, links }) => {
  return (
    <>
      <Banner>
        Get <strong>5% off</strong> your next purchase or restore at <strong>Gamer Corner</strong> and <strong>Card Revive</strong>. Use code: <strong>TCGCalendar5%</strong>
      </Banner>
      <NavbarStyled>
        <NavbarBrand to="/">
          <img src={logo} alt="Logo" />
          <DropdownStyled>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Competitions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/pokemon-competitions-nsw">Pokémon NSW</Dropdown.Item>
            </Dropdown.Menu>
          </DropdownStyled>
        </NavbarBrand>
        <NavbarMenu>
          
          <PoweredBy>Powered by</PoweredBy>
          {links.map((link, index) => (
            <NavbarLink key={index} to={{ pathname: link.url }} target="_blank">
              <img src={link.src} alt={link.alt} />
            </NavbarLink>
          ))}
        </NavbarMenu>
      </NavbarStyled>
    </>
  );
};

export default Navbar;
