import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import styled, { createGlobalStyle } from 'styled-components';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import EventModal from './EventModal';
import { EventClickArg } from '@fullcalendar/core';

export const CalendarContainer = styled.div`
  padding: 10px;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  box-shadow: 10px 10px 1px rgba(0, 0, 0, 100);
  border: 3px solid #000000;
`;

const GlobalStyles = createGlobalStyle`
  a {
    text-decoration: none !important;
    color: black !important;
    font-weight: bold;
  }
  .fc-view-harness {
    border-radius: 15px !important;
    overflow: hidden !important;
  }

  /* Grid line styles */
  .fc-col-header-cell-cushion {
    color: black !important;
    font-weight: bold !important;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(233, 179, 57, 0.8) !important;
  }
  /* Responsive button styles */
  @media (max-width: 768px) {
    .fc-button {
      padding: 0.5em .5em !important;
      font-size: 0.75em !important;
    }
    .fc-toolbar-title {
      font-size: 1.2em !important;
    }
  }
`;

interface Props {
  searchTerm: string;
  endpoint: string;
}

interface IEvent {
  id: string;
  title: string;
  description: string;
  extendedProps: {
    location: string;
    descriptionUrl?: string;
    ticketCost?: string;
    tags?: string[];
    image?: string;
    shop?: string;
    address?: string;
  };
  start: string;
  end: string;
  url?: string;
}

function transformEvent(event: any): IEvent {
  return {
    id: event.id || '',
    title: event.title || '',
    description: event.description || '',
    start: event.start || '',
    end: event.end || '',
    url: event.url || '',
    extendedProps: {
      location: event.location || '',
      descriptionUrl: event.extendedProps?.descriptionUrl || '',
      ticketCost: event.extendedProps?.ticketCost || '',
      tags: event.extendedProps?.tags || [],
      image: event.extendedProps?.image || '',
      shop: event.extendedProps?.shop || '',
      address: event.extendedProps?.address || '',
    }
  };
}

const Calendar: React.FC<Props> = ({ searchTerm, endpoint }) => {
  const [events, setEvents] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(endpoint);
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, [endpoint]);

  const filteredEvents = events.filter(event =>
    event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    event.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEventClick = (clickInfo: EventClickArg) => {
    clickInfo.jsEvent.preventDefault();
    setSelectedEvent(transformEvent(clickInfo.event));
    setModalShow(true);
  };

  return (
    <>
      <GlobalStyles />
      <CalendarContainer>
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin]}
          initialView='dayGridMonth'
          themeSystem='sandstone'
          weekends={true}
          events={filteredEvents}
          eventClick={handleEventClick}
          aspectRatio={1.5}
          contentHeight="auto"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listMonth'
          }}
          views={{
            dayGridMonth: {
              buttonText: 'Month'
            },
            listMonth: {
              buttonText: 'List Month'
            }
          }}
          windowResize={(arg) => {
            if (arg.view.type !== 'listMonth' && window.innerWidth < 768) {
              arg.view.calendar.changeView('listMonth');
            } else if (arg.view.type !== 'dayGridMonth' && window.innerWidth >= 768) {
              arg.view.calendar.changeView('dayGridMonth');
            }
          }}
        />
        {selectedEvent && 
          <EventModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            event={selectedEvent}
          />
        }
      </CalendarContainer>
    </>
  );
};

export default Calendar;