import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface IEvent {
    id: string;
    title: string;
    description: string;
    extendedProps: {
      location: string;
      descriptionUrl?: string;
      ticketCost?: string;
      tags?: string[];
      image?: string;
      shop?: string;
      address?: string;
    };
    start: string;
    end: string;
    url?: string;
}

interface IProps {
    show: boolean;
    onHide: () => void;
    event: IEvent;
}


function getEventImage(title: string) {
    const lowerTitle = title.toLowerCase();
    if (lowerTitle.includes('league cup') && lowerTitle.includes('vg')) {
        return '/play-pokemon-vgcs.png';
    } else if (lowerTitle.includes('league challenge') && lowerTitle.includes('vg')) {
        return '/play-pokemon-vgcs.png';
    } else if (lowerTitle.includes('league cup')) {
        return '/pokemon_league_logo_en.png';
    } else if (lowerTitle.includes('league challenge')) {
        return '/pokemon_league_logo_en.png';
    } else if (lowerTitle.includes('pre release')) {
        return '/pokemon-prerelease.png';
    } else if (lowerTitle.includes('go challenge')) {
        return '/play-pokemon-logo-GO.png';
    } else if (lowerTitle.includes('go cup')) {
        return '/play-pokemon-logo-GO.png';
    }
    return null;
}

const EventModal: React.FC<IProps> = ({ show, onHide, event }) => {
    const eventTypeImage = getEventImage(event.title);
    const imageToShow = event.extendedProps.image || eventTypeImage;
    
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{event.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageToShow ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ backgroundColor: '#3788d8', border: '2px solid #000000', borderRadius: '5px', padding: '10px' }}>
                            <img src={imageToShow} alt={event.title} style={{ width: '20vh', borderRadius: '5px' }} />
                        </div>
                    </div>
                ) : (
                    <p>No Event Logo</p>
                )}
                <br />
                <b><strong>Event Information</strong></b>
                <br />
                <br />
                {event.extendedProps.descriptionUrl && (
                    <div>
                        <strong>Url: </strong>
                        <a href={event.extendedProps.descriptionUrl} target="_blank" rel="noopener noreferrer" className="text-primary">
                            {event.extendedProps.descriptionUrl}
                        </a>
                    </div>
                )}
                <br />
                <p><strong>Start Date:</strong> {new Date(event.start).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} - <strong>End Date:</strong> {new Date(event.end).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                {event.extendedProps.ticketCost && <p><strong>Ticket Price:</strong> {event.extendedProps.ticketCost}</p>}
                {event.extendedProps.shop && <p><strong>Shop:</strong> {event.extendedProps.shop}</p>}
                {event.extendedProps.address && (
                    <p>
                        <strong>Address:</strong><br />
                        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.extendedProps.address)}`} target="_blank" rel="noopener noreferrer" className="text-primary">
                            {event.extendedProps.address}
                        </a>
                    </p>
                )}
                <br />
                {event.extendedProps.tags && event.extendedProps.tags.length > 0 && (
                    <>
                        <strong>Tags: </strong>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {event.extendedProps.tags.map((tag, index) => (
                                <b key={index}>
                                    <span style={{ backgroundColor: 'orange', color: 'white', borderRadius: '10px', padding: '2px 5px', marginRight: '5px', maxWidth: '200px', fontSize: '0.8rem', overflowWrap: 'break-word', marginBottom: '5px' }}>
                                        {tag}
                                    </span>
                                </b>
                            ))}
                        </div>
                    </>
                )}
                <br />
                <div className="text-end">
                    <button
                        className="btn btn-dark"
                        onClick={() => window.open(event.url, "_blank", "noopener,noreferrer")}
                    >
                        Add to Calendar
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EventModal;